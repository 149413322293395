.c-NavigatorBanner-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.25);
    padding: 30px;
    z-index: 1000;
}

.c-NavigatorBanner {
    display: flex;
    background-color: rgba(255, 255, 255, 0.95);
    border: 1px solid var(--global-color-default-medium);
    border-radius: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .5em;
    padding: 20px;
    z-index: 1010;
}

.c-NavigatorBanner .button {
    background: var(--global-color-default-base);
}

.c-NavigatorBanner .navigationBanner-logo {
    background-image: url(../../assets/images/logo.png);
    width: 100px;
    height: 100px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.c-NavigatorBanner .navigationBanner-copy {
    text-align: center;
    line-height: 1.25;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.c-NavigatorBanner .navigationBanner-controls {
    font-size: 18px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: -20px;
}

.c-NavigatorBanner .navigationBanner-controls .button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--global-color-default-base);
    width: 32px;
    height: 32px;
}

.c-NavigatorBanner .navigationBanner-copyTitle {
    text-align: center;
    line-height: 1.25;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 1em;
}


.c-NavigatorBanner .navigationBanner-copy .navigationBanner-copyCta {
    font-weight: bold;
}

@media only screen and (max-width: 641px) {
    .c-NavigatorBanner-container {
        display: flex;
    }
}