.c-Modal-Container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.25);
    animation: --modal-animation-backgroundFadeIn 250ms ease-out;
}

.c-Modal-Container,
.c-Modal,
.c-Modal .modal-content {
    height: 100%;
}

.c-Modal {
    background-color: #fff;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    animation: --modal-animation-fadeIn 450ms ease-out;
    padding: 20px;
}

.c-Modal .modal-content {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.c-Modal .modal-header {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
}

.c-Modal .modal-header .c-Title {
    flex: 1;
    margin-bottom: 0;
}

.c-Modal .modal-body {
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.c-Modal .modal-body::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.c-Modal .modal-controls {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 20px;
    border-top: 1px solid var(--global-color-default-light);
}

.c-Modal .modal-controls * {
    flex: 1;
}

@media only screen and (max-width: 641px) {
    .c-Modal {
        flex: 1;
    }
}

@media only screen and (min-width: 642px) {
    .c-Modal {
        border-radius: 15px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .25);
        max-width: 641px;
        min-width: 320px;
    }

    .c-Modal,
    .c-Modal .modal-content {
        height: fit-content;
        max-height: 75vh;
    }
}

@keyframes --modal-animation-slideUp {
    0% {
        margin-top: 100%;
    }

    100% {
        margin-top: 0;
    }
}

@keyframes --modal-animation-fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes --modal-animation-backgroundFadeIn {
    0% {
        background-color: transparent;
    }

    100% {
        background-color: rgba(0, 0, 0, .25);
    }
}