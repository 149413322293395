.c-FilterSlider {
    padding: 1em;
    border-radius: 15px;
    background-color: #fff;
}

.c-FilterSlider .filterSlider-title {
    font-size: 16px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    margin-top: 1em;
}

.c-FilterSlider .filterSlider-filter {
    padding: 1em;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}