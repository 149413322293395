@import url('https://fonts.googleapis.com/css2?family=Archivo&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

a {
    text-decoration: none;
    color: var(--global-color-default-dark);
    transition: color 100ms;
}

span.bold {
    font-weight: bold;
}

p.default {
    line-height: 1.5em;
}

p {
    margin-bottom: 1.5em;
}