.c-NavigationBar {
    position: relative;
    height: 72px;
    background: rgb(2,229,55);
    background: radial-gradient(circle, rgba(2,229,55,1) 0%, rgba(1,195,108,1) 50%); 
    margin-bottom: 10px;
}

.c-NavigationBar h1 {
    position: absolute;
    left: 50%;
    top: -20px;
    transform: translateX(-50%);
    width: 100px;
    height: 100px;
    background: #fff url('../../assets/images/logo.png') no-repeat center center;
    background-size: 50%;
    border-radius: 145px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
}

.c-NavigationBar h1 span {
    display: none;
}