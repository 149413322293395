.c-LayoutPage {
    margin: 0 auto;
    min-width: 280px;
    box-sizing: border-box;
    padding: 20px 20px 60px 20px;
}

@media only screen and (max-width: 1280px) {
    .c-LayoutPage {
        width: 100%;
    }
}

@media only screen and (min-width: 1280px) {
    .c-LayoutPage {
        max-width: 1280px;
    }
}