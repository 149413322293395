.p-Main {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.disclaimer {
    padding: .75em;
    font-size: 13px;
    line-height: 1.25;
    background-color: #E7F4FC;
    border: 1px solid #83B0CC;
    border-radius: 10px;
}

.links {
    display: flex;
    gap: 10px;
    align-items: top;
}

.links + .links {
    margin-top: 20px;
}

.links a.links-link {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 1px solid #ccc;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 13px;
}

a.links-link:hover {
    background-color: #eee;
}

a.links-link .link-icon {
    width: 28px;
    height: 28px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

a.links-link .link-icon.telegram {
    background-image: url(../../assets/images/icon-telegram.png);
}

a.links-link .link-icon.facebook {
    background-image: url(../../assets/images/icon-facebook.png);
}

.links-link .link-description {
    text-align: center;
    line-height: 1.25;
}

@media only screen and (min-width: 768px) {
    .links {
        flex-direction: row;
    }
}

@media only screen and (max-width: 768px) {
    .links {
        flex-direction: column;
    }

    .links-link {
        padding: 15px 10px;
    }
}