:root {
    --global-color-default-extraLight: #FAFAFA;
    --global-color-default-light: #EEEEEE;
    --global-color-default-base: #CCCCCC;
    --global-color-default-medium: #7E7E7E;
    --global-color-default-dark: #616161;
    --global-color-default-extraDark: #212121;
    
    --global-color-orange-extraLight: #FFF5F2;
    --global-color-orange-light: #FFD8CB;
    --global-color-orange-base: #FF6939;
    --global-color-orange-medium: #DB481A;
    --global-color-orange-dark: #9C3211;
    --global-color-orange-extraDark: #4F1908;

    --global-color-blue-extraLight: #ccdcfa;
    --global-color-blue-light: #aedafe;
    --global-color-blue-base: #306BDC;
    --global-color-blue-medium: #174ad5;
    --global-color-blue-dark: #174EB7;
    --global-color-blue-extraDark: #101e64;
    
    --global-color-red-extraLight: #fee2e2;
    --global-color-red-light: #fca5a5;
    --global-color-red-base: #ef4444;
    --global-color-red-medium: #b91c1c;
    --global-color-red-dark: #7f1d1d;
    --global-color-red-extraDark: #450a0a;
    
    --global-color-green-extraLight: #ecfccb;
    --global-color-green-light: #bef264;
    --global-color-green-base: #84cc16;
    --global-color-green-medium: #4d7c0f;
    --global-color-green-dark: #365314;
    --global-color-green-extraDark: #1a2e05;
}