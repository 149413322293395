.c-Deals .deals-filters {
    margin-bottom: 20px;
}

.c-Deals .deals-dealsCollection {
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.c-Deals .deals-dealsSectionNoResults {
    text-align: center;
    padding: 20px 0;
}

.c-Deals .deals-dealsSectionNoResults h2 {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: .5em;
}

.c-Deals .deals-dealsSectionNoResults p {
    font-size: 16px;
    margin-bottom: 0;
}

.c-Deals .deals-dealsSection {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.c-Deals .deals-dealsSectionDate .bubble {
    padding: .75em;
    width: 100%;
    font-size: 16px;
    background-color: #01c36c;
}

.c-Deals .deals-dealsSectionItems {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 15px;
    grid-row-gap: 30px;
}

@media only screen and (min-width: 1280px) {
    .c-Deals .deals-dealsSectionItems {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media only screen and (max-width: 1280px) {
    .c-Deals .deals-dealsSectionItems {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .c-Deals .deals-dealsSectionItems .c-Deal {
        width: 100%;
    }
}

@media only screen and (max-width: 860px) {
    .c-Deals .deals-dealsSectionItems {
        grid-template-columns: 1fr 1fr;
    }

    .c-Deals .deals-dealsSectionDate {
        position: sticky;
        top: 15px;
        left: 0;
        right: 0;
    }

    .c-Deals .deals-dealsSectionDate .bubble {
        box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.25);
    }
}

@media only screen and (max-width: 641px) {
    .c-Deals .deals-dealsSectionItems {
        grid-template-columns: 1fr;
    }
}