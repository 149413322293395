@import 'reset.css';
@import 'fonts.css';
@import 'colors.css';
@import 'animation.css';
@import 'icons.css';
@import 'layout.css';

html, body {
    height: 100%;
    padding: 0;
    margin: 0;
}

body {
    background-color: #F9F9F9;
    touch-action: pan-x pan-y;
}

body,
input,
textarea,
select,
button,
textarea {
    font-family: 'Inter', Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: var(--global-color-default-dark);
}

body {
    background-color: #eee;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
}

strong {
    font-weight: bold;
}

.no-wrap {
    white-space: nowrap;
}

button {
    border: 0;
    cursor: pointer;
}

a {
    text-decoration: none;
    color: #000;
}

a:visited,
a:clicked {
    color: #000;
}

.button {
    width: 100%;
    justify-content: center;
    text-align: center;
    padding: 10px;
    border-radius: 200px;
    background: rgb(1,195,108);
    background: linear-gradient(0deg, rgba(1,195,108,1) 0%, rgba(2,229,55,1) 100%);
    color: #fff;
    box-sizing: border-box;
    font-weight: bold;
}

.button .button-title {
    flex: 1;
    text-align: center;
    padding-left: 15px;
    font-weight: 700;
}

.button .button-icon {
    margin-left: -100%;
}

.button:hover {
    background: rgb(0,164,91);
    background: linear-gradient(0deg, rgb(0, 164, 91) 0%, rgba(2,229,55,1) 100%); 
}

.bubble {
    padding: .5em .75em;
    background-color: #000;
    color: #fff;
    border-radius: 200px;
    display: inline-block;
    font-weight: 700;
    box-sizing: border-box;
    text-align: center;
}

.bubble.bubble-red {
    background-color: #FAE8E8;
    color: #CD0000;
}

.bubble.bubble-blue {
    background-color: #21A0EE;
    color: #fff;
}

.bubble.bubble-green {
    background-color: #00a45b;
    color: #fff;
}