.disabled {
    pointer-events: none;
}

.no-scroll {
    overflow: hidden;
}

.scrollbar-styled {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.scrollbar-styled::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.section + .section {
    margin-top: 15px;
}

.section-centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-padded {
    padding: 20px 20px 40px 20px;
}

.modal-padded.centered {
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.overlay-padded {
    padding: 20px;
}

.form-field {
    padding: 15px;
    width: 100%;
    background-color: var(--global-color-default-light);
    border-radius: 5px;
    box-sizing: border-box;
}

.form-field label {
    display: block;
    margin-bottom: .5em;
}

.form-controls {
    margin-top: 15px;
    display: flex;
}