.animation-fadeIn {
    opacity: 0;
    animation: animation-fadeIn 300ms ease-in-out forwards;
}

.animation-fadeOut {
    opacity: 0;
    animation: animation-fadeOut 300ms ease-in-out forwards;
}

@keyframes animation-fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes animation-fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}