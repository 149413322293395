.c-Deal {
    transition: all 250ms;
}

.c-Deal.disabled {
    opacity: .5;
}

.c-Deal .collection-item-wrapper {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    gap: 10px;
}

.c-Deal .item-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.c-Deal .item-bodyDetails {
    display: flex;
    gap: 10px;
    align-items: start;
    /* background-color: #eee; */
    padding: 10px;
}

.c-Deal .item-headerSite {
    padding: 10px 10px 0 10px;
    /* background: rgb(222,222,222); */
    /* background: linear-gradient(0deg, rgba(222,222,222,1) 0%, rgba(255,255,255,1) 100%); */
}

.c-Deal .item-header {
    display: flex;
    gap: 10px;
    padding: 5px 10px 10px 10px;
    align-items: center;
}

.c-Deal .item-copyToClipboard {
    cursor: pointer;
}

.c-Deal .item-copyToClipboard .bi {
    display: inline-block;
    margin-left: .5em;
}

.c-Deal .item-copyToClipboard:hover {
    opacity: 0.5;
}

/* .c-Deal .item-header {} */

.c-Deal .item-titleDead {
    background-color: #FAE8E8;
    color: #CD0000;
    padding: .5em;
    text-align: center;
    font-weight: bold;
}

.c-Deal .item-headerTitle {
    font-size: 16px;
    font-weight: 700;
    color: #000;
    flex: 1;
}

.c-Deal .item-image {
    width: 100px;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 5px;
}

.c-Deal .item-price {
    display: flex;
    gap: 5px;
    align-items: center;
    overflow: hidden;
    font-weight: 700;
}

.c-Deal .item-price .item-headerDiscount {
    font-weight: bold;
    padding-left: 10px;
}

.c-Deal .item-price .item-price-originalPrice {
    text-align: center;
}

.c-Deal .item-price .item-price-discountPrice {
    flex: 1;
    color: #000;
}

.c-Deal .item-description {
    line-height: 1.5;
    flex: 1;
}

.c-Deal .item-descriptionCopy {
    margin-bottom: 10px;
    word-break: break-word;
}

.c-Deal .item-instructions {
    background-color: #E7F4FC;
    padding: 10px;
    gap: 10px;
    display: flex;
    flex-direction: column;
}

.c-Deal .item-instructions .item-instructions-title {
    color: #000;
}

.c-Deal .item-instructions .item-instructions-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style-type: circle;
    list-style-position: inside;
}

.c-Deal .item-instructions .item-instructions-list .bubble {
    font-size: 12px;
    padding: .5em .75em;
}

.c-Deal .item-ctas {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 0 10px 10px 10px;
}

.c-Deal .item-cta .button {
    border-radius: 6px;
}

.c-Deal .item-cta .button .button-title {
    padding: 0 !important;
}

.c-Deal .item-cta.deal-itemViewDeal {
    flex: 1;
}

.c-Deal .item-cta.deal-itemReportDead .button {
    background: #E7F4FC;
    color: #535f67;
}

.c-Deal .item-cta.deal-itemReportDead .button:hover {
    background: #d7e5ed;
    color: #535f67;
}

.c-Deals .item-customMessage {
    padding: 10px;
    background-color: #E7F4FC;
    margin: 10px 0;
    border-radius: 5px;
}